import Footer from "../../../Shade/Footer/Footer";
import Headings from "../../../Shade/Headings/Headings";
import Line from "../../../Shade/Line/Line";
import "./CardCourses.css";
import rightarrow from "../../../Assets/rightarrow.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { API_URL } from "../../../server";
import { AppProvider } from "../../../ContextApi/AppContext";
import { Button, Modal, Row, Col, Container, Card } from "react-bootstrap";

import award from "../../../Assets/award.svg";

const CardCourses = () => {
  const navigate = useNavigate();
  const { state, setState } = useContext(AppProvider);
  const [cardDetails, setCardDetails] = useState([]);

  const token = sessionStorage.getItem("token");
  useEffect(() => {
    getCardDetails();
  }, []);

  const getCardDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    const raw = "";
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "getAllCourse", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == true) {
          console.log("getAllCourse", result);
          setCardDetails(result.data.courses);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const gotocoursesoutline = (
    subjectname,
    courseName,
    subid,
    coursename,
    accept_badge_url
  ) => {
    navigate("/CourseOutline", {
      state: {
        subjectname: subjectname,
        courseName: courseName,
        subid: subid,
        coursename: coursename,
        acceptbadgeurl: accept_badge_url,
      },
    });
  };
  const roundRatio = (completionRatio) => {
    const [current, total] = completionRatio.split("/").map(Number);
    return current / total;
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="flex-grow-1">
        <Headings screenHeadingName="courses." />
        <p style={{ marginTop: "-20px" }}></p>
        <Line />
        <p className="cpara" style={{ padding: "0px 20px" }}>
          Enrol in professionally designed courses, learn in-demand skills from
          industry tutors, and earn a verified certificate upon completion.
        </p>
        <Line />

       
        <Container fluid>
          <Row xs={1} sm={3} md={2} lg={3} className="p-3">
            {cardDetails?.map((details, index) => (
              <div
                key={index}
                className="col-12 col-md-4 col-lg-4 mb-4"
                onClick={() => {
                  setState(details.courseKit);
                  gotocoursesoutline(
                    details.mySubject[0],
                    details.courseName,
                    details._id,
                    details.courseName,
                    details.accept_badge_url
                  );
                }}
              >
                <div
                  className="card h-100 pt-3 pb-3 pe-3 ps-3"
                  style={{
                    backgroundColor:
                      details.accept_badge_url !== ""
                        ? "#E5ECE3"
                        : "transparent",
                  }}
                >
                 
                  <div
                    className="d-flex justify-content-between align-items-center gap-5"
                    style={{ minHeight: "2.5rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <p className="ctitle m-0">{details.courseName}</p>
                    </div>
                    {details.accept_badge_url !== "" ? (
                      <div>
                        <img src={award} />
                      </div>
                    ) : (
                      <div
                        style={{ width: "1.5rem", height: "1.5rem" }}
                        className="ms-auto d-flex align-items-center justify-content-center"
                      >
                        <CircularProgressbar
                          strokeWidth={15}
                          maxValue={1}
                          value={roundRatio(details.completionRatio)}
                          styles={buildStyles({
                            pathColor: "#005230",
                          })}
                        />
                      </div>
                    )}
                  </div>
                 
                  <div
                    className="d-flex justify-content-between align-items-center gap-5"
                    style={{ minHeight: "3rem" }}
                  >
                    <div className="w-75">
                      <p className="cpara w-100 text-left m-0">
                        {details.courseDescription.length > 100
                          ? details.courseDescription
                              .split(" ")
                              .slice(0, 5)
                              .join(" ") + "..."
                          : details.courseDescription}
                      </p>
                    </div>
                    <div>
                      <p className="cpara text-nowrap m-0">
                        {details.course[0]?.Answer[0].courseTime} -hour course
                      </p>
                    </div>
                  </div>
                  
                  <div
                    className="d-flex justify-content-between align-items-center gap-5"
                    style={{ minHeight: "2.5rem" }}
                  >
                    <div>
                      <button className="csub d-flex align-items-center">
                        {details.mySubject[0]}{" "}
                        <img
                          src={rightarrow}
                          style={{ height: "0.8rem", width: "0.8rem" }}
                        />
                      </button>
                    </div>
                    <div>
                      <button className="cstart">Start now</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>

       
      </div>
      <Line />
      <Footer />
    </div>
  );
};

export default CardCourses;