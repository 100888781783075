import { useNavigate } from "react-router-dom";
import "./Footer.css"

const Footer = () => {

    const navigate = useNavigate();

    const webNavigate = (weblink) => {
        window.open(weblink)
    }

    return (
        <div className="footerbody  pt-1 pb-3">
            <div className="row justify-content-between">
                <div className="col-md-8 d-flex flex-wrap">
                    <span className="percentage me-4">© Recess Learning, Inc.</span>
                    <span onClick={() => webNavigate('https://www.recess.education')} className="percentage me-4">
                        <img className="icons" src={require("../../Assets/lib.png")} /> Learn More
                    </span>
                    <span onClick={() => webNavigate('https://www.recess.education/contact')} className="percentage me-4">
                        <img className="icons" src={require("../../Assets/email.png")} /> Get in Touch
                    </span>
                    <span onClick={() => webNavigate('https://www.recess.education/terms')} className="percentage me-4">
                        <img className="icons" src={require("../../Assets/termsandconditions.png")} /> Terms & Conditions
                    </span>
                    <span onClick={() => webNavigate('https://www.recess.education/privacy')} className="percentage me-4">
                        <img className="icons" src={require("../../Assets/policy.png")} /> Privacy Policy
                    </span>
                </div>
                <div className="col-md-3 text-md-end text-start">
                    <span className="percentage">
                        <img className="icons" src={require("../../Assets/learnatrecess.png")} /> Learn at Recess.
                    </span>
                </div>
            </div>
        </div>

    );
}
export default Footer;
