import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import './CourseOutline.css';
import "./CourseOutlineModule.css";
import develop from "../../../Assets/develop.svg";
import build from "../../../Assets/build.svg";
import contribute from '../../../Assets/contribute.svg';
import enjoy from "../../../Assets/enjoy.svg";

import coursekitvideo from "../../../Assets/coursekitvideo.svg";
import { AppProvider } from "../../../ContextApi/AppContext";
import { API_URL, IMG_PATH } from "../../../server";
import { Link, useNavigate } from "react-router-dom";

const CourseOutlineModule = ({ name, coursename }) => {

  const [subscription, setSubscription] = useState(false);
  const [courseKit, setCourseKit] = useState(false);
  const [preview, setPreview] = useState(false); // Fixed typo here: "setPrevew" should be "setPreview"

  // Extracting state from context
  const { state, setState, prev, setPrev, index, setIndex } = useContext(AppProvider);

  console.log('state', state); // Ensure that state is being fetched correctly
  console.log('prev', prev);
  console.log('index', index);

  const registerid = sessionStorage.getItem('registerid');
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  // Set courseKit or preview modal based on the prop name
  useEffect(() => {
    if (name === 'coursekit') {
      setCourseKit(true);
    } else if (name === 'preview') {
      setPreview(true);
    }
  }, [name]);

  // Fetch user details on component mount
  useEffect(() => {
    if (token && registerid) {
      mydetails();
    }
  }, [token, registerid]);

  const mydetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({ "_id": registerid });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(API_URL + "getUsers", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("getUsers", result);
        if (result && result.data) {
          setSubscription(result.data.profile.subscription); // Set subscription state
        }
      })
      .catch((error) => console.error('Error fetching user data:', error));
  };
  const navsubscription = () => {
    navigate('/SettingsMainScreen/SettingSubscription', {
      state: 7
    })
  }

  return (
    <>
      {subscription ? (
        <Modal centered show={courseKit}>
          <Modal.Header
            onClick={() => setCourseKit(false)}
            className="btn btn-close ms-auto"
          >
          </Modal.Header>
          <Modal.Body className="ps-5 pe-5" >
            <p className="cotitle1">{coursename}</p>
            <p className="coursemodulekit">Course Kit</p>
            <p className="copara text-muted">{state?.courseKitDescription}</p>
            <hr />
            <p className="coursemodule">What Is {coursename}</p>
            <p className="copara">{state?.courseKitpreviewText}</p>
            <img src={IMG_PATH + state?.courseKitpreviewImage} style={{ width: '100%', height: '12rem' }} />
            <p className="copara mt-3">Use the link here to access the tool: <Link to={state?.courseKitpreviewLink} style={{ color: 'black' }}>{state?.courseKitpreviewLink}</Link></p>
            <p className="copara">A collection materials designed to support you during this course. A <br /> collection of materials designed to support you during this course. A</p>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal centered show={courseKit}>
          <Modal.Header
            onClick={() => setCourseKit(false)}
            className="btn btn-close ms-auto"
          >
          </Modal.Header>
          <Modal.Body className="ps-5 pe-5">
            <div>
              <div>
                <p className="cotitle1">Your Pathway to Industry</p>
                <p className="coursemoduleprev">Skills, Certifications, <strong className="copara">and</strong> <strong style={{ color: '#660076' }}>Opportunities</strong>.</p>
                <p className="copara">Join <strong>Recess Pathway</strong> to continue and:</p>
              </div>
              <div>
                <div className="d-flex align-items-center gap-3">
                  <span><img className="subscribeimg" src={develop} /></span>
                  <span className="copara">Develop in-demand skills through <strong>structured courses and certifications.</strong></span>
                </div>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <span><img className="subscribeimg" src={build} /></span>
                  <span className="copara">Build a standout resume with our <strong>AI-powered resume builder</strong> <span className="text-muted">(on its way)</span></span>
                </div>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <span><img className="subscribeimg" src={contribute} /></span>
                  <span className="copara">Contribute to real-world employer tasks through our industry portal <span className="text-muted">(on its way)</span></span>
                </div>
                <div className="d-flex align-items-center gap-3 mt-4">
                  <span><img className="subscribeimg" src={enjoy} /></span>
                  <span className="copara">Enjoy resource partner discounts on <strong>tools, supplies, and more.</strong></span>
                </div>
              </div>
              <div className="mt-4 mb-4">
                <button className="subbuttonone" onClick={() => navsubscription()}>Start your free trial today</button>
              </div>
              <div className="copara align-center" style={{ textAlign: "center" }}>
  <p>
    You can also talk to your school about{" "}
    <a 
      href="https://www.recess.education/schools" 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ color: "#007bff", textDecoration: "underline" }} // Optional styling
    >
      https://www.recess.education/schools
    </a>
  </p>
</div>

            </div>
          </Modal.Body>
        </Modal>
      )}

      {subscription ? (
        <Modal centered show={preview}>
          <Modal.Header
            onClick={() => setPreview(false)}
            className="btn btn-close ms-auto"
          >
          </Modal.Header>
          <Modal.Body className="ps-5 pe-5">
            <p className="cotitle1">{coursename}</p>
            <p className="coursemoduleprev">Session {index} Preview</p>
            <p className="text-muted copara">{prev?.previewDescription}</p>
            <hr />
            <p className="coursemodule">Let’s get started</p>
            <p className="copara">{prev?.previewText}</p>
            <img src={IMG_PATH + prev?.previewImage} style={{ width: '100%', height: '11rem' }} />
            <p className="copara mt-3">Use the link here to access the tool: <a>google.com</a></p>
            <p className="copara mt-3">Access a tool here: <Link to={prev?.previewLink} style={{ color: 'black' }}>
              {prev?.previewLink}
            </Link>
            </p>
            <p className="coursemodule">What you need to practice</p>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal centered show={preview}>
          <Modal.Header
            onClick={() => setPreview(false)}
            className="btn btn-close ms-auto"
          >
          </Modal.Header>
          <Modal.Body className="ps-5 pe-5">
            <div>
              <div>
                <p className="cotitle1">Your Pathway to Industry</p>
                <p className="coursemoduleprev">Skills, Certifications, <strong className="copara">and</strong> <strong style={{ color: '#660076' }}>Opportunities</strong>.</p>
                <p className="copara">Join <strong>Recess Pathway</strong> to continue and:</p>
              </div>
              <div>
                <div className="d-flex align-items-center gap-3">
                  <span><img className="subscribeimg" src={develop} /></span>
                  <span className="copara">Develop in-demand skills through <strong>structured courses and certifications.</strong></span>
                </div>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <span><img className="subscribeimg" src={build} /></span>
                  <span className="copara">Build a standout resume with our <strong>AI-powered resume builder</strong> <span className="text-muted">(on its way)</span></span>
                </div>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <span><img className="subscribeimg" src={contribute} /></span>
                  <span className="copara">Contribute to real-world employer tasks through our industry portal <span className="text-muted">(on its way)</span></span>
                </div>
                <div className="d-flex align-items-center gap-3 mt-4">
                  <span><img className="subscribeimg" src={enjoy} /></span>
                  <span className="copara">Enjoy resource partner discounts on <strong>tools, supplies, and more.</strong></span>
                </div>
              </div>
              <div className="mt-4 mb-4">
                <button className="subbuttonone" onClick={() => navsubscription()}>Start your free trial today</button>
              </div>
              <div className="copara align-center" style={{ textAlign: "center" }}>
  <p>
    You can also talk to your school about{" "}
    <a 
      href="https://www.recess.education/schools" 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ color: "#007bff", textDecoration: "underline" }} // Optional styling
    >
      https://www.recess.education/schools
    </a>
  </p>
</div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default CourseOutlineModule;
